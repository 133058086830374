import React, { useState, useRef } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { Link } from 'react-router-dom';

import { CSSTransition } from 'react-transition-group';
import { Container, Grid, Box, Typography } from '@mui/material';
import JuvalfriAnimation from '../home/JuvalfriAnimation';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import DrawIcon from '@mui/icons-material/Draw';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import VerifiedIcon from '@mui/icons-material/Verified';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import './TransitionsStyles.css'
import { StyledButtonStepper1, StyledButtonStepper2 } from './StyledComponents';

function getSteps() {
    return ['Revisa nuestro catálogo', 'Cotización', 'Modelo Nuevo', 'Fabricación', 'Control de Calidad', 'Entrega o envío'];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return <>
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography style={{ fontSize: '26px', maxWidth: '700px', textAlign: 'center' }}>
                        Si estás buscando un <span style={{ color: '#7ee787', fontWeight: '500' }}>teclado o membrana</span> puedes
                        revisar nuestro <span style={{ color: '#7ee787', fontWeight: '500' }}>catálogo</span>, contamos con una amplia
                        variedad de productos. Actualizamos periódicamente.
                    </Typography>
                </Box>
                <div>
                    <Link to='/catalogoMembranas' style={{ textDecoration: 'none' }} target='_blank'>
                        <PictureAsPdfIcon style={{ fontSize: '80px', marginLeft: '24px', color: '#9b8aff' }} />
                    </Link>
                </div>
            </>;
        case 1:
            return <>
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography style={{ fontSize: '24px', maxWidth: '700px', textAlign: 'center' }}>
                        Si requieres una <span style={{ color: '#7ee787', fontWeight: '500' }}>cotización</span>, puedes enviarnos
                        las <span style={{ color: '#7ee787', fontWeight: '500' }}>dimensiones exteriores</span> de la membrana, una <span style={{ color: '#7ee787', fontWeight: '500' }}>foto </span>
                        de referencia y la <span style={{ color: '#7ee787', fontWeight: '500' }}>cantidad</span> que necesitas. Es
                        necesario <span style={{ color: '#7ee787', fontWeight: '500' }}>especificar si requieres</span> un
                        <span style={{ color: '#7ee787', fontWeight: '500' }}> teclado con circuito impreso</span> o solo la <span style={{ color: '#7ee787', fontWeight: '500' }}>membrana (overlay)</span>.
                    </Typography>
                </Box>
                <div>
                    <PriceCheckIcon style={{ fontSize: '80px', marginLeft: '24px', color: '#9b8aff', fontWeight: '500' }} />
                </div>
            </>;
        case 2:
            return <>
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography style={{ fontSize: '24px', maxWidth: '700px', textAlign: 'center' }}>
                        En caso de que no encuentres la membrana que
                        necesitas en nuestro catálogo, <span style={{ color: '#7ee787', fontWeight: '500' }}> podemos fabricar el
                            diseño completo del teclado o membrana que
                            necesites</span>. En este caso es necesario que nos proporciones
                        una <span style={{ color: '#7ee787', fontWeight: '500' }}> muestra física</span> o un
                        <span style={{ color: '#7ee787', fontWeight: '500' }}> archivo CAD del modelo</span>.
                    </Typography>
                    <div>
                        <DrawIcon style={{ fontSize: '80px', marginLeft: '24px', color: '#9b8aff', fontWeight: '500' }} />
                    </div>
                </Box>
            </>;
        case 3:
            return <>
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography style={{ fontSize: '24px', maxWidth: '700px', textAlign: 'center' }}>
                        Una vez acordadas con el cliente las características de la membrana, se procede
                        a la <span style={{ color: '#7ee787', fontWeight: '500' }}> etapa de fabricación. </span> En esta etapa ya
                        <span style={{ color: '#7ee787', fontWeight: '500' }}> no es posible hacer cambios en el diseño</span>.
                    </Typography>
                    <div>
                        <PrecisionManufacturingIcon style={{ fontSize: '80px', marginLeft: '24px', color: '#9b8aff', fontWeight: '500' }} />
                    </div>
                </Box>
            </>;

        case 4:
            return <>
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography style={{ fontSize: '24px', maxWidth: '700px', textAlign: 'center' }}>
                        Antes de la entrega del producto al cliente,
                        <span style={{ color: '#7ee787', fontWeight: '500' }}> cada pieza fabricada por Juvalfri </span> es
                        sometida a una <span style={{ color: '#7ee787', fontWeight: '500' }}> revisión detallada </span> por nuestros
                        ingenieros para asegurar que el producto cumpla
                        con los <span style={{ color: '#7ee787', fontWeight: '500' }}> estándares de calidad</span>.
                    </Typography>
                    <div>
                        <VerifiedIcon style={{ fontSize: '80px', marginLeft: '24px', color: '#9b8aff', fontWeight: '500' }} />
                    </div>
                </Box>
            </>;
        case 5:
            return <>
                <Box style={{ display: 'flex', flexDirection: 'row ', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography style={{ fontSize: '24px', maxWidth: '700px', textAlign: 'center' }}>
                        Finalmente, <span style={{ color: '#7ee787', fontWeight: '500' }}> la pieza es empaquetada o sellada </span>
                        para <span style={{ color: '#7ee787', fontWeight: '500' }}> recolección en nuestras instalaciones o envío a domicilio </span> por
                        paquetería, los detalles de la entrega son acordados
                        en la etapa de cotización.
                    </Typography>
                    <div>
                        <LocalShippingIcon style={{ fontSize: '80px', marginLeft: '24px', color: '#9b8aff', fontWeight: '500' }} />
                    </div>
                </Box>
            </>;
        default:
            return 'Que estas tratando de hacer? Mejor ponte en contacto con el Ing. Jorge Benítez :D';
    }
}

export default function StepperH() {
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const [state, setState] = useState(false);
    const nodeRef1 = useRef(null);
    const nodeRef2 = useRef(null);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setState(state => !state);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setState(state => !state);
    };

    const handleReset = () => {
        setActiveStep(0);
        setState(state => !state);
    };

    return (
        <Container maxWidth='xl' style={{ borderRadius: '20px' }}>
            <Stepper activeStep={activeStep} alternativeLabel >
                {steps.map((label) => (
                    <Step key={label} sx={{
                        '& .MuiStepIcon-root': { color: '#9b8aff' },
                        '& MuiStepIcon-root': { color: '#000' }
                    }}>
                        <StepLabel style={{ transform: 'scale(1.25)' }}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box style={{ marginTop: '100px' }}>
                {activeStep === steps.length ? (
                    <Box>
                        <CSSTransition
                            in={state}
                            timeout={300}
                            nodeRef={nodeRef2}
                            classNames='fade'>
                            <Grid container ref={nodeRef2}>
                                <Grid item xs={12}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '48px', flexDirection: 'column', textAlign: 'center' }}>
                                        <JuvalfriAnimation />
                                        <Typography variant='h6' style={{ marginTop: '32px' }} >
                                            ¡Contáctanos ahora y descubre cómo podemos ayudarte a alcanzar tus objetivos!
                                        </Typography>
                                        <Typography variant='body1'>
                                            Nuestro equipo está a tu disposición para brindarte la mejor asesoría.
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <StyledButtonStepper1 onClick={handleReset} color='primary' variant='contained'>Reiniciar</StyledButtonStepper1>
                                        <Link to='/contacto' style={{ textDecoration: 'none' }}>
                                            <StyledButtonStepper2 color='secondary' variant='contained'>Contacto</StyledButtonStepper2>
                                        </Link>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CSSTransition>

                    </Box>
                ) : (
                    <Box>
                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <StyledButtonStepper2
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                variant="contained"
                                color='secondary'
                            >
                                Paso atras
                            </StyledButtonStepper2>
                            <StyledButtonStepper1 variant="contained" color="primary" onClick={handleNext} style={{ marginLeft: '18px' }}>
                                {activeStep === steps.length - 1 ? 'Final' : 'Siguiente'}
                            </StyledButtonStepper1>
                        </Box>
                        <CSSTransition
                            in={state}
                            timeout={300}
                            nodeRef={nodeRef1}
                            classNames='fade'>
                            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '48px', marginTop: '48px' }} ref={nodeRef1}>
                                {getStepContent(activeStep)}
                            </Box>
                        </CSSTransition>
                    </Box>
                )}
            </Box>
        </Container>
    );
}