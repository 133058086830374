import styled from 'styled-components';

export const StyledDivider = styled.div({
    marginTop: '32px',
    overflow: 'hidden',
    // marginBottom: '72px',

    '& img': {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',

        '@media (max-width: 1200px)': {
            width: '1800px',
            marginLeft: '-600px'
        },

        '@media (max-width: 900px)': {
            width: '1500px',
            marginLeft: '-600px'
        },

        '@media (max-width: 700px)': {
            width: '1500px',
            marginLeft: '-600px'
        },

        '@media (max-width: 600px)': {
            width: '1500px',
            marginLeft: '-800px'
        },

        '@media (max-width: 420px)': {
            width: '1500px',
            marginLeft: '-900px'
        },
    },

})

export const StyledDividerBottom = styled.div({
    marginTop: '98px',
    overflow: 'hidden',
    marginBottom: '120px',

    '& img': {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',

        '@media (max-width: 1200px)': {
            width: '1800px',
            marginRight: '-600px'
        },

        '@media (max-width: 900px)': {
            width: '1500px',
            marginRight: '-600px'
        },

        '@media (max-width: 700px)': {
            width: '1500px',
            marginRight: '-600px'
        },

        '@media (max-width: 600px)': {
            width: '1500px',
            marginRight: '-800px'
        },

        '@media (max-width: 420px)': {
            width: '1500px',
            marginRight: '-900px'
        },
    },

})

export const StyledTitle = styled.p({
    fontSize: '48px',
    // textShadow: '1px 1px 2px #0d1117',
    fontWeight: '800',
    transition: 'all 0.3s ease-in-out',
    marginBottom: '64px',
    lineHeight: '96px',
    color: '#FFF',
    paddingLeft: '12px',
    marginTop: '64px',

    '@media (max-width: 600px)': {
        fontSize: '64px',
        lineHeight: '72px',
        transition: 'all 0.3s ease-in-out',
    },

    '@media (max-width: 420px)': {
        fontSize: '48px',
        lineHeight: '64px',
        transition: 'all 0.3s ease-in-out',
    },
    '& span': {
        fontSize: '72px',
    }
})

export const StyledTitle2 = styled.p({
    fontSize: '72px',
    // textShadow: '1px 1px 2px #0d1117',
    fontWeight: '800',
    transition: 'all 0.3s ease-in-out',
    marginBottom: '64px',
    lineHeight: '96px',
    color: '#FFF',
    paddingLeft: '12px',
    marginTop: '64px',

    '@media (max-width: 600px)': {
        fontSize: '64px',
        lineHeight: '72px',
        transition: 'all 0.3s ease-in-out',
    },

    '@media (max-width: 420px)': {
        fontSize: '48px',
        lineHeight: '52px',
        transition: 'all 0.3s ease-in-out',
    },
})

export const StyledSubtitle = styled.p({
    fontSize: '32px',
    textShadow: '1px 1px 2px #0d1117',
    transition: 'all 0.3s ease-in-out',
    // marginBottom: '128px',
    marginTop: '0px',
    maxWidth: '910px',
    lineHeight: '44px',
    color: '#FFF',
    paddingLeft: '12px',

    '@media (max-width: 900px)': {
        maxWidth: '600px',
    },

    '@media (max-width: 600px)': {
        fontSize: '24px',
        marginBottom: '64px',
        lineHeight: '32px',
        transition: 'all 0.3s ease-in-out',
    },

    '@media (max-width: 420px)': {
        fontSize: '20px',
        marginBottom: '64px',
        lineHeight: '28px',
        transition: 'all 0.3s ease-in-out',
    },

    '& span': {
        color: '#9b8aff',
    },
})

export const StyledButton = styled.button({
    width: '200px',
    height: '70px',
    borderRadius: '20px',
    border: 'solid 2px #9b8aff',
    cursor: 'pointer',
    transition: 'all 0.12s ease-in-out',
    backgroundColor: '#00000011',
    color: '#FFF',
    fontSize: '24px',
    fontWeight: '600',
    marginLeft: '12px',
    fontFamily: 'Mona Sans',

    '&:hover': {
        boxShadow: '0px 0px 10px #9b8aff',
    },

    '&:active': {
        transform: 'scale(0.99) translate(0px, 2px)',
        boxShadow: '0px 0px 12px #CbBaff',
        backgroundColor: '#00000022',
    },
})

export const StyledButtonMas = styled.button({
    width: '200px',
    height: '70px',
    borderRadius: '20px',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    background: 'linear-gradient(60deg, #5b4aff 0%, #8b7aff 100%)',
    color: '#FFF',
    fontSize: '24px',
    fontWeight: '600',
    marginTop: '32px',
    marginLeft: '12px',
    fontFamily: 'Mona Sans',
    boxShadow: '3px 3px 10px #0d1117',

    '&:hover': {
        background: 'linear-gradient(60deg, #4b3aff 25%, #9b8aff 100%)',
        transition: 'all 0.2s ease-in-out',
        boxShadow: '3px 3px 10px #FFF',
    },

    '&:active': {
        background: 'linear-gradient(60deg, #2b1aDD 25%, #9b8aff 100%)',
        transition: 'all 0.2s ease-in-out',
        transform: 'scale(0.98) translate(0px, 2px)',
        boxShadow: '3px 3px 10px #FFFFFFAA',
    }
})

export const StyledSecondaryTitle = styled.p({
    fontSize: '28px',
    fontWeight: '500',
    transition: 'all 0.3s ease-in-out',
    lineHeight: '32px',
    color: '#FFF',
    paddingBottom: '32px',
    paddingLeft: '12px',
    maxWidth: '960px',
    paddingTop: '32px',

    '@media (max-width: 600px)': {
        fontSize: '22px',
        lineHeight: '26px',
        transition: 'all 0.3s ease-in-out',
        paddingBottom: '64px',
        marginTop: '96px',
    },

    '@media (max-width: 420px)': {
        fontSize: '22px',
        lineHeight: '26px',
        transition: 'all 0.3s ease-in-out',
    },

    '& span': {
        color: '#939aff',
    }
})

export const StyledDivImage = styled.div({
    transition: 'all 0.3s ease-in-out',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',

    '& img': {
        maxWidth: '500px',
        width: '100%',
        height: 'auto',
        transition: 'all 0.3s ease-in-out',
        borderRadius: '20px',
        boxShadow: '0px 0px 10px #0d1117',

        '&:hover': {
            transform: 'scale(1.015)',
        },

        '@media (max-width: 900px)': {
            width: '90%',
            maxWidth: '400px',
        },

    },

})

export const StyledButtonStepper1 = styled.button({
    width: '180px',
    height: '50px',
    borderRadius: '20px',
    border: 'solid 2px #7ee787',
    cursor: 'pointer',
    transition: 'all 0.12s ease-in-out',
    backgroundColor: '#00000011',
    color: '#FFF',
    fontSize: '24px',
    fontWeight: '600',
    marginTop: '32px',
    marginLeft: '12px',
    fontFamily: 'Mona Sans',

    '&:hover': {
        boxShadow: '0px 0px 10px #7ee787',
    },

    '&:active': {
        transform: 'scale(0.99) translate(0px, 2px)',
        boxShadow: '0px 0px 12px #65EB9D',
        backgroundColor: '#00000022',
    },

    '&:disabled': {
        backgroundColor: '#00000011',
        color: '#FFF',
        border: 'solid 2px #888',

        '&:hover': {
            boxShadow: '0px 0px 0px #7ee78700',
        },
    }
})

export const StyledButtonStepper2 = styled.button({
    width: '180px',
    height: '50px',
    borderRadius: '20px',
    border: 'solid 2px #9b8aff',
    cursor: 'pointer',
    transition: 'all 0.12s ease-in-out',
    backgroundColor: '#00000011',
    color: '#FFF',
    fontSize: '24px',
    fontWeight: '600',
    marginTop: '32px',
    marginLeft: '12px',
    fontFamily: 'Mona Sans',

    '&:hover': {
        boxShadow: '0px 0px 10px #9b8aff',
    },

    '&:active': {
        transform: 'scale(0.99) translate(0px, 2px)',
        boxShadow: '0px 0px 12px #CbBaff',
        backgroundColor: '#00000022',
    },

    '&:disabled': {
        backgroundColor: '#00000011',
        color: '#FFF',
        border: 'solid 2px #888',

        '&:hover': {
            boxShadow: '0px 0px 0px #7ee78700',
        },
    }
})

export const StyledRowTextImg = styled.div({
    display: 'flex',
    backgroundColor: '#161b22',
    color: '#FFF',
    borderRadius: '12px',
    padding: '64px',
    border: 'solid 0.2px #2c3239',
    marginBottom: '86px',

    '@media (max-width: 900px)': {
        padding: '32px',
    },


    '@media (max-width: 600px)': {
        padding: '18px',
    },

    '& div': {
        '&.txt-container': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'start',

            '@media (max-width: 900px)': {
                alignItems: 'center',
                textAlign: 'center',
            },
        },

        '&.img-container': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            '@media (max-width: 900px)': {
                paddingTop: '32px',
                alignItems: 'center',
                textAlign: 'center',
            },
        }
    },

    '& p': {
        width: '100%',

        '&.title': {
            fontSize: '48px',
            marginTop: '0px',
            marginBottom: '32px',
            lineHeight: '52px',
            fontWeight: '700',
            transition: 'all 0.3s ease-in-out',

            '@media (max-width: 1200px)': {
                fontSize: '40px',
                lineHeight: '48px',
                fontWeight: '600',
            },

            '@media (max-width: 900px)': {
                fontSize: '40px',
                lineHeight: '48px',
                fontWeight: '600',
            },

            '@media (max-width: 600px)': {
                fontSize: '38px',
                lineHeight: '42px',
                fontWeight: '600',
            },
        },

        '&.subtitle': {
            fontSize: '28px',
            lineHeight: '38px',
            fontWeight: '600',
            margin: '0px',
            transition: 'all 0.3s ease-in-out',
            color: '#FFF',

            '@media (max-width: 1200px)': {
                fontSize: '24px',
                lineHeight: '30px',
                fontWeight: '600',
            },

            '@media (max-width: 900px)': {
                fontSize: '24px',
                lineHeight: '30px',
                fontWeight: '600',
            },

            '@media (max-width: 600px)': {
                fontSize: '22px',
                lineHeight: '28px',
                fontWeight: '500',
            },
        },
        '& span': {
            color: '#939aff',
        }
    },
})

export const StyledRowTextImg2 = styled.div({
    display: 'flex',
    background: 'linear-gradient(135deg, rgba(193,142,255,0.25) 0%, rgba(46,9,121,0) 51%, rgba(151,146,254,0.25) 100%)',
    color: '#FFF',
    borderRadius: '20px',
    padding: '64px',
    boxShadow: '0px 0px 10px #0d1117',
    marginBottom: '48px',
    transition: 'all 1s ease-in-out',

    '@media (max-width: 900px)': {
        padding: '32px',
    },

    '@media (max-width: 600px)': {
        padding: '18px',
    },

    '& div': {
        '&.txt-container': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'start',

            '@media (max-width: 900px)': {
                alignItems: 'center',
                textAlign: 'center',
            },
        },

        '&.img-container': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            '@media (max-width: 900px)': {
                paddingTop: '32px',
                alignItems: 'center',
                textAlign: 'center',
            },
        }
    },

    '& p': {
        width: '100%',

        '&.title': {
            fontSize: '48px',
            marginTop: '0px',
            marginBottom: '32px',
            lineHeight: '52px',
            fontWeight: '700',
            transition: 'all 0.3s ease-in-out',

            '@media (max-width: 1200px)': {
                fontSize: '40px',
                lineHeight: '48px',
                fontWeight: '600',
            },

            '@media (max-width: 900px)': {
                fontSize: '40px',
                lineHeight: '48px',
                fontWeight: '600',
            },

            '@media (max-width: 600px)': {
                fontSize: '38px',
                lineHeight: '42px',
                fontWeight: '600',
            },
        },

        '&.subtitle': {
            fontSize: '34px',
            lineHeight: '48px',
            fontWeight: '600',
            marginTop: '64px',
            marginBottom: '0px',
            transition: 'all 0.3s ease-in-out',
            color: '#FFF',
            textAlign: 'center',

            '@media (max-width: 1200px)': {
                fontSize: '24px',
                lineHeight: '30px',
                fontWeight: '600',
                marginTop: '32px',
            },

            '@media (max-width: 900px)': {
                fontSize: '24px',
                lineHeight: '30px',
                fontWeight: '600',
                marginTop: '0px',
                marginBottom: '12px'
            },

            '@media (max-width: 600px)': {
                fontSize: '22px',
                lineHeight: '28px',
                fontWeight: '500',
            },
        },
        '& span': {
            color: '#58C666',
        }
    },

    '&:hover': {
        background: 'linear-gradient(150deg, rgba(193,142,255,0.30) 0%, rgba(46,9,121,0.10) 51%, rgba(151,146,254,0.30) 100%)',
        transition: 'all 1s ease-in-out',
    }
})

export const StyledTitle3 = styled.p({
    fontSize: '64px',
    fontWeight: '800',
    transition: 'all 0.3s ease-in-out',
    marginBottom: '24px',
    lineHeight: '64px',
    color: '#FFF',
    paddingLeft: '12px',
    marginTop: '0px',
    textAlign: 'center',

    '@media (max-width: 600px)': {
        fontSize: '64px',
        lineHeight: '72px',
        transition: 'all 0.3s ease-in-out',
    },

    '@media (max-width: 420px)': {
        fontSize: '48px',
        lineHeight: '52px',
        transition: 'all 0.3s ease-in-out',
    },

    '& span': {
        color: '#9b8aff',
    },
})

export const StyledSubtitle2 = styled.p({
    fontSize: '32px',
    transition: 'all 0.3s ease-in-out',
    marginBottom: '0px',
    marginTop: '0px',
    textAlign: 'center',
    lineHeight: '44px',
    color: '#7D8590',
    paddingLeft: '12px',

    '@media (max-width: 600px)': {
        fontSize: '24px',
        lineHeight: '32px',
        transition: 'all 0.3s ease-in-out',
    },

    '@media (max-width: 420px)': {
        fontSize: '20px',
        lineHeight: '28px',
        transition: 'all 0.3s ease-in-out',
    },

    '& span': {
        color: '#9b8aff',
    },
})