import { React, useEffect, useState } from 'react';
import { Container, Grid, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";

import CatalogoPiezas from '../catalogs/CatalogoPiezas';

import {
    StyledTitle, StyledSubtitle, StyledButton, StyledButtonMas, StyledSecondaryTitle, StyledDivImage,
    StyledDivider, StyledDividerBottom, StyledRowTextImg, StyledRowTextImg2, StyledTitle2, StyledTitle3, StyledSubtitle2
} from './StyledComponents';

import AOS from 'aos';
import "aos/dist/aos.css";

import ReactPlayer from 'react-player'

import ManufacturaBackground from './ManufacturaBackground';

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";

const Manufactura = () => {

    const [showCatalogo, setShowCatalogo] = useState(false);
    const [index, setIndex] = useState(-1);
    const [index2, setIndex2] = useState(-1);
    const slides = [
        { src: "./images/manufactura/img-1.webp", width: 1000, height: 1000, alt: 'Cabezas de Lámpara Quirúrgica Brandon Med impresas en 3D con ABS', description: 'Cabezas de Lámpara Quirúrgica Brandon Med impresas en 3D con ABS', title: 'Lámpara Quirúrgica Brandon Med' },
        { src: "./images/manufactura/img-2.webp", width: 1000, height: 1000, alt: 'Cabezas de Lámpara Quirúrgica Brandon Med impresas en 3D con ABS', description: 'Cabezas de Lámpara Quirúrgica Brandon Med impresas en 3D con ABS', title: 'Lámpara Quirúrgica Brandon Med' },
        { src: "./images/manufactura/img-11.webp", width: 1000, height: 1000, alt: 'Control de mano para Mesa Quirúrgica Steris ASC2000 impresa en 3D con PETG', description: 'Control de mano para Mesa Quirúrgica Steris ASC2000 impresa en 3D con PETG', title: 'Mesa Quirúrgica Steris ASC2000' },
        { src: "./images/manufactura/img-4.webp", width: 1000, height: 1000, alt: 'Bandeja para Desfirbilador Zoll M Series', description: 'Bandeja de impresora (con rodillo) para desfibrilador Zoll M Series', title: 'Bandeja Desfirbilador Zoll M Series' },
        { src: "./images/manufactura/img-5.webp", width: 1000, height: 1000, alt: 'Impresora para Desfibrilador Philips Heartstart', description: 'Tapa de impresora con soporte de rodillo y liberador para Desfibrilador Philips Heartstart', title: 'Impresora para Desfibrilador Philips Heartstart' },
        { src: "./images/manufactura/img-6.webp", width: 1000, height: 1000, alt: 'Tapa compatible con Aspirador Medela Basic 300', description: 'Tapa compatible con Aspirador Medela Basic 300', title: 'Aspirador Medela Basic 300' },
        { src: "./images/manufactura/img-7.webp", width: 1000, height: 1000, alt: 'Carcasa superior para desfibrilador Zoll M Series', description: 'Carcasa superior para desfibrilador Zoll M Series', title: 'Desfibrilador Zoll M Series' },
        { src: "./images/manufactura/img-8.webp", width: 1000, height: 1000, alt: 'Porta-Electrodo compatible con Desfibrilador Nihon Kohden', description: 'Porta-Electrodo compatible con Desfibrilador Nihon Kohden', title: 'Porta-Electrodo Desfibrilador Nihon Kohden' },
        { src: "./images/manufactura/img-9.webp", width: 1000, height: 1000, alt: 'Porta-Electrodo compatible con Desfibrilador Cardio Aid 200B', description: 'Porta-Electrodo compatible con Desfibrilador Cardio Aid 200B', title: 'Porta-Electrodo Desfibrilador Cardio Aid 200B' },
        { src: "./images/manufactura/img-10.webp", width: 1000, height: 1000, alt: 'Control de mano para Mesa Quirúrgica Trumpf', description: 'Control de mano para Mesa Quirúrgica Trumpf', title: 'Mesa Quirúrgica Trumpf' },
        { src: "./images/manufactura/img-12.webp", width: 1000, height: 1000, alt: 'Bandeja para impresora compatible con Electrocardiógrafo Schiller AT-1', description: 'Bandeja para impresora compatible con Electrocardiógrafo Schiller AT-1', title: 'Electrocardiógrafo Schiller AT-1' },
        { src: "./images/manufactura/img-13.webp", width: 1000, height: 1000, alt: 'Esquineros compatibles con cuna de calor Hill Rom Drager Resuscitaire', description: 'Esquineros compatibles con cuna de calor Hill Rom Drager Resuscitaire', title: 'Hill Rom Drager Resuscitaire' },
        { src: "./images/manufactura/img-14.webp", width: 1000, height: 1000, alt: 'Panel frontal Cafetera Gaggia', description: 'Panel frontal para Cafetera Gaggia', title: 'Panel Cafetera Gaggia' },
        { src: "./images/manufactura/img-15.webp", width: 1000, height: 1000, alt: 'Control de mano para Mesa Quirúrgica Steris ASC2000 impresa en 3D con PETG', description: 'Control de mano para Mesa Quirúrgica Steris ASC2000 impresa en 3D con PETG', title: 'Control Mesa Quirúrgica Steris ASC2000' },
    ]

    const slides2 = [
        { src: "./images/manufactura/res-5", description: 'Cople para amortiguador de cama hospitalaria Hill Rom', alt: 'Cople para amortiguador de cama hospitalaria Hill Rom (impresion 3D resina)' },
        { src: "./images/manufactura/res-6", description: 'Conector de aire compatible con aspirador Medela Basic', alt: 'Conector de aire compatible con aspirador Medela Basic (impresion 3D resina)' },
        { src: "./images/manufactura/res-7", description: 'Alineador de colchón compatible con cama hospitalaria Hill-Rom', alt: 'Alineador de colchón compatible con cama hospitalaria Hill-Rom (impresion 3D resina)' },
        // { src: "./images/manufactura/res-8", description: 'Alineador de colchón compatible con cama hospitalaria Hill-Rom', alt: 'Alineador de colchón compatible con cama hospitalaria Hill-Rom (impresion 3D resina)' },
        { src: "./images/manufactura/res-9", description: 'Engranes para cafetera industrial', alt: 'Engranes para cafetera industrial (impresion 3D resina)' },
        { src: "./images/manufactura/res-10", description: 'Liberador de barandal para cama hospitalaria', alt: 'Liberador de barandal para cama hospitalaria (impresion 3D resina)' },
        { src: "./images/manufactura/res-1", description: 'Tapa de impresora con soporte de rodillo para Esterilizador Tuttnauer', alt: 'Tapa de impresora con soporte de rodillo para Esterilizador Tuttnauer (impresion 3D resina)' },
        { src: "./images/manufactura/res-2", description: 'Clip para barandal de cama hospitalaria', alt: 'Clip para barandal de cama hospitalaria (impresion 3D resina)' },
        { src: "./images/manufactura/res-3", description: 'Gomas de pedal para cama Hill Rom', alt: 'Gomas de pedal para cama Hill Rom (impresion 3D resina)' },
        { src: "./images/manufactura/res-4", description: 'Mandibula inferior impresa en 3D', alt: 'Mandibula inferior impresa en 3D (impresion 3D resina)' },
    ]

    useEffect(() => {
        AOS.init({ duration: 400 })
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        document.title = "Manufactura";
    }, [])

    const handleShowCatalog = (e) => {
        e.preventDefault();
        setShowCatalogo(showCatalogo => !showCatalogo);
    }

    return (
        <>
            <Container maxWidth='xxl' style={{ margin: '0px', padding: '0px' }}>
                <ManufacturaBackground />
            </Container>
            <Container maxWidth='xxl'>

                <Container maxWidth='xl' style={{ marginBottom: '0px', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <Box sx={{ marginTop: '48px' }} >
                        <StyledSubtitle as='h2' style={{ fontWeight: '600', lineHeight: '42px', fontSize: '36px', color: '#C3FCFD', textAlign: 'center' }} data-aos='fade-up'>
                            Somos expertos en la fabricación y adaptación de piezas y componentes funcionales.
                        </StyledSubtitle>
                    </Box>
                    <Box sx={{ marginTop: '32px', textAlign: 'center' }} >
                        <StyledSubtitle style={{ fontWeight: '600', lineHeight: '42px', fontSize: '36px', marginTop: '12px' }} data-aos='fade-up'> ¡Contáctanos ahora!</StyledSubtitle>
                        <Link to='/contacto' style={{ textDecoration: 'none' }}>
                            <StyledButton data-aos='fade-up'>Contacto</StyledButton>
                        </Link>
                    </Box>
                </Container>

                <Container maxWidth='xl'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} sx={{ textAlign: 'center' }} data-aos='fade-up'>
                            <StyledTitle2 as='h1' >
                                Fabricación digital
                            </StyledTitle2>
                        </Grid>

                        <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} data-aos='fade-up'>
                            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <StyledSecondaryTitle as='h2' style={{ fontWeight: '600', lineHeight: '36px', fontSize: '30px', marginTop: '0px' }} data-aos="fade-up">
                                    Fabricamos <span>piezas</span> y <span>refacciones</span> de <span>alta calidad</span> para una amplia variedad de aplicaciones.
                                </StyledSecondaryTitle>
                            </Box>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} data-aos='fade-up' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                    <Box className='zoomPhoto'>
                                        <picture>
                                            <source srcSet='./images/manufactura/cnc-process.webp' type='image/webp' />
                                            <source srcSet='./images/manufactura/cnc-process.jpg' type='image/jpg' />
                                            <img src='./images/manufactura/cnc-process.webp' alt='maquina cnc haas' style={{ width: '90%', borderRadius: '20px', boxShadow: '0px 0px 10px #0d1117' }} />
                                        </picture>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} data-aos='fade-up' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                    <Box className='zoomPhoto'>
                                        <picture>
                                            <source srcSet='./images/manufactura/pieza-cnc.webp' type='image/webp' />
                                            <source srcSet='./images/manufactura/pieza-cnc.jpg' type='image/jpg' />
                                            <img src='./images/manufactura/pieza-cnc.webp' alt='maquina cnc haas' style={{ width: '90%', borderRadius: '20px', boxShadow: '0px 0px 10px #0d1117' }} />
                                        </picture>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }} data-aos='fade-up'>
                            <StyledDivImage>
                                <picture>
                                    <source srcSet='./images/manufactura/cnc-machine.webp' type='image/webp' />
                                    <source srcSet='./images/manufactura/cnc-machine.jpg' type='image/jpg' />
                                    <img src='./images/manufactura/cnc-machine.webp' alt='maquina cnc haas' style={{ maxWidth: '500px', borderRadius: '20px', boxShadow: '0px 0px 10px #0d1117' }} />
                                </picture>
                            </StyledDivImage>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ marginTop: '32px' }}>
                        <Grid item xs={12} md={12} data-aos='fade-up'>
                            <StyledButton style={{ marginBottom: '0px' }} onClick={(e) => handleShowCatalog(e)}>{showCatalogo ? 'Ocultar' : 'Ver Catálogo'}</StyledButton>
                            {showCatalogo ? <CatalogoPiezas /> : null}
                        </Grid>
                        <Grid item xs={12} md={12} data-aos='fade-up'>
                            <Box>
                                <StyledSecondaryTitle data-aos="fade-up" style={{ marginTop: '0px', paddingBottom: '0px' }}>
                                    Utilizamos tecnología avanzada, como <span style={{ color: '#fcd38d' }}>máquinas CNC</span> e <span style={{ color: '#fcd38d' }}>impresoras 3D</span>, para fabricar <span style={{ color: '#fcd38d' }}>piezas</span> y <span style={{ color: '#fcd38d' }}>refacciones</span>.
                                    Con materiales de ingeniería, <span style={{ color: '#fcd38d' }}>diseñamos</span> y <span style={{ color: '#fcd38d' }}>replicamos </span>componentes de alta precisión para diversas aplicaciones.
                                </StyledSecondaryTitle>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={12} md={12} data-aos='fade-up'>
                            <Box>
                                <StyledSecondaryTitle data-aos="fade-up" style={{ marginTop: '32px', paddingBottom: '0px', fontWeight: '400' }}>
                                    Explore nuestras capacidades y descubra cómo podemos transformar sus ideas en <span>piezas reales y funcionales</span>.
                                </StyledSecondaryTitle>
                            </Box>
                        </Grid> */}
                    </Grid>

                </Container>
            </Container>


            <Container maxWidth='xxl' style={{ background: '#1B2133', paddingLeft: '0px', paddingRight: '0px' }} >
                <Container maxWidth='xxl' style={{ background: '#1B2133', paddingLeft: '0px', paddingRight: '0px' }}>
                    <StyledDivider>
                        <img src='./images/section-space-v1.svg' alt='div-img' />
                    </StyledDivider>
                </Container>

                <Container maxWidth='xl' style={{ background: '#1B2133' }}>

                    <StyledTitle as='h2'>
                        <span>Impresión 3D de Alta Gama:</span><br /> Tecnología de vanguardia para Profesionales y Empresas
                    </StyledTitle>

                    <Box data-aos="zoom-in" style={{ marginTop: '48px' }}>
                        <StyledRowTextImg2>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6} lg={5} className='img-container'>
                                    <div>
                                        <img src='./images/manufactura/gif_bambu.gif' alt='impresion 3d de alta velocidad' style={{ width: '100%', maxWidth: '400px', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6} lg={7} className='txt-container'>
                                    {/* <Box sx={{ display: { xs: 'block', md: 'none' }}}>
                                        <Typography className='title' style={{ color: '#7ee787', marginTop: '68px' }}>Impresión 3D perfecta en cada ocasión</Typography>
                                    </Box> */}
                                    {/* <Box sx={{ display: { xs: 'none', md: 'block' }}}>
                                        <Typography className='title' style={{ color: '#7ee787' }}>Impresión 3D perfecta en cada ocasión</Typography>
                                    </Box> */}
                                    <Box style={{ maxWidth: '900px' }}>
                                        <picture>
                                            <source srcSet='./images/manufactura/bambulab.webp' type='image/webp' />
                                            <source srcSet='./images/manufactura/bambulab.jpg' type='image/jpg' />
                                            <img src='./images/manufactura/bambulab.webp' alt='impresoras bambulab' style={{ width: '100%', borderRadius: '20px', boxShadow: '0px 0px 10px #0d1117' }} />
                                        </picture>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <p className='subtitle'>
                                        Contamos con impresoras 3D de <span>última generación</span> que nos permiten fabricar piezas de precisión rápidamente.
                                    </p>
                                </Grid>
                            </Grid>
                        </StyledRowTextImg2>
                    </Box>

                    <Grid item xs={12} md={12} style={{ zIndex: '10' }}>
                        <StyledSubtitle style={{ fontWeight: '500' }}>
                            Piezas impresas en 3D y sus aplicaciones
                        </StyledSubtitle>
                        <PhotoAlbum
                            layout="rows"
                            photos={slides}
                            targetRowHeight={200}
                            onClick={({ index: current }) => setIndex(current)}
                        />

                        <Lightbox
                            plugins={[Captions]}
                            captions={{ descriptionTextAlign: 'center' }}
                            index={index}
                            slides={slides}
                            open={index >= 0}
                            close={() => setIndex(-1)}
                        />
                    </Grid>

                    <Box data-aos="zoom-in" style={{ marginTop: '48px' }}>
                        <StyledRowTextImg2>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6} lg={7} className='txt-container'>
                                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                                        <Typography className='title' style={{ color: '#FFEDEB', marginTop: '68px' }}>Precisión y repetibilidad láser</Typography>
                                    </Box>
                                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                        <Typography className='title' style={{ color: '#FFEDEB' }}>Precisión y repetibilidad láser</Typography>
                                    </Box>
                                    <p className='subtitle'>
                                        Gracias a la tecnología <span>Low Force Stereolithography </span> podemos fabricar piezas con resinas de distintos
                                        materiales con una precisión de hasta <span> 25 micrómetros</span>.
                                    </p>
                                </Grid>
                                <Grid item xs={12} md={6} lg={5} className='img-container'>
                                    <div>
                                        <img src='./images/manufactura/formlabs.gif' alt='impresion con resinas formlabs' style={{ width: '100%', maxWidth: '400px', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                                    </div>
                                </Grid>
                            </Grid>
                        </StyledRowTextImg2>
                    </Box>

                    <Grid container spacing={2} sx={{ marginTop: '32px' }}>
                        <Grid item xs={12} md={12} style={{ zIndex: '10' }}>
                            <StyledSubtitle style={{ fontWeight: '700' }}>
                                Piezas fabricadas con resinas de ingeniería Formlabs
                            </StyledSubtitle>
                        </Grid>
                    </Grid>

                    <Box sx={{ display: { xs: 'none', md: 'block' }, marginTop: '32px' }}>
                        <Grid container spacing={5} sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            {
                                slides2.map((slide, index) => (
                                    <Grid item sm={12} md={6} lg={3} key={index + 'drsly'} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', maxWidth: '400px', padding: '12px', alignItems: 'center' }} data-aos="fade-up">
                                        <div style={{ maxWidth: '420px', height: 'auto', marginBottom: '16px' }} className='zoomPhoto'>
                                            <picture>
                                                <source srcSet={slide.src + '.webp'} type='image/webp' />
                                                <source srcSet={slide.src + '.jpg'} type='image/jpg' />
                                                <img src={slide.src + '.webp'} alt={slide.alt} style={{ width: '100%', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                                            </picture>
                                        </div>
                                        <StyledSubtitle style={{ color: '#BDC5D0', textAlign: 'center', fontSize: '16px', lineHeight: '24px', fontWeight: '500', maxWidth: '312px' }}>
                                            {slide.description}
                                        </StyledSubtitle>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>

                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'start' }}>
                            {
                                slides2.map((slide, index) => (
                                    <Grid item xs={12} key={index + 'drs'} data-aos="fade-up" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                        <div style={{ maxWidth: '420px', height: 'auto', marginBottom: '16px' }} className='zoomPhoto'>
                                            <picture>
                                                <source srcSet={slide.src + '.webp'} type='image/webp' />
                                                <source srcSet={slide.src + '.jpg'} type='image/jpg' />
                                                <img src={slide.src + '.webp'} alt={slide.alt} style={{ width: '100%', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                                            </picture>
                                        </div>
                                        <StyledSubtitle style={{ color: '#BDC5D0', textAlign: 'center', fontSize: '16px', lineHeight: '24px', fontWeight: '500', maxWidth: '312px' }}>
                                            {slide.description}
                                        </StyledSubtitle>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                </Container>
                <Container maxWidth='xl' sx={{ marginTop: '180px', marginBottom: '64px' }}>
                    <StyledTitle3 >
                        Explore nuestras capacidades y descubra cómo podemos transformar sus ideas en <span>piezas reales y funcionales</span>.
                    </StyledTitle3>
                </Container>

                <Container maxWidth='xl' style={{ display: 'flex', justifyContent: 'center' }} sx={{ my: 10 }}>
                    <Link to='/contacto' style={{ textDecoration: 'none' }}>
                        <StyledButton data-aos='fade-up'>Contacto</StyledButton>
                    </Link>
                </Container>

                {/* <Grid container spacing={2} sx={{ marginTop: '32px' }}>
                    <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <Box data-aos="zoom-in" style={{ maxWidth: '900px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', borderRadius: '20px' }}>
                            <ReactPlayer url='./images/manufactura/video/impresion-3d-2.m3u8' width='100%' height='100%' controls={true} playing={true} muted={true} loop={true} alt='timelapse de impresion multimaterial' />
                        </Box>
                    </Grid>
                </Grid> */}

                <Container maxWidth='xxl' style={{ paddingLeft: '0px', paddingRight: '0px', background: 'linear-gradient(180deg, #1B2133 30%, #171726 100%)', paddingBottom: '120px' }}>

                </Container>
            </Container>
        </>
    );
};

export default Manufactura;
