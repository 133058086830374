import { React, useEffect, useState } from 'react';
import { Container, Grid, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import CatalogoMembranas from '../../catalogs/CatalogoMembranas';

import { StyledTitle, StyledSubtitle, StyledButton, StyledButtonMas, StyledSecondaryTitle, StyledDivImage, StyledDivider, StyledDividerBottom } from './StyledComponents';

import AOS from 'aos';
import "aos/dist/aos.css";

const MembranasHome = () => {

    const [showCatalogo, setShowCatalogo] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init({ duration: 400 })
    }, [])

    const handleShowCatalog = (e) => {
        e.preventDefault();
        setShowCatalogo(!showCatalogo);
    }

    return (
        <>
            <Container maxWidth='xxl' style={{ background: '#1B2133', paddingLeft: '0px', paddingRight: '0px' }}>
                <StyledDivider>
                    <img src='./images/section-space-v1.svg' alt='div-img' />
                </StyledDivider>
            </Container>
            <Container maxWidth='xl' sx={{ overflowY: 'hidden' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }} data-aos='fade-up'>
                        <StyledTitle as='h1'>
                            Teclados de membrana
                        </StyledTitle>
                        <StyledSubtitle> Teclados (<span>Membrane Switch</span>). </StyledSubtitle>
                        <StyledSubtitle> Carátulas (<span>Overlay</span>). </StyledSubtitle>
                        <StyledSubtitle> Etiquetas. </StyledSubtitle>
                        <StyledSubtitle> Asesoría personalizada. </StyledSubtitle>
                        <StyledButtonMas onClick={() => navigate('/membranas')}>Conocer mas</StyledButtonMas>
                    </Grid>
                    <Grid item xs={12} md={4} data-aos='zoom-in'>
                        <StyledDivImage>
                            <picture>
                                <source srcSet='./images/membranas/membranas-final.webp' type='image/webp' />
                                <source srcSet='./images/membranas/membranas-final.jpg' type='image/jpg' />
                                <img src='./images/membranas/membranas-final.webp' alt='teclado de membrana para equipo medico' />
                            </picture>
                        </StyledDivImage>
                    </Grid>
                </Grid>

                <Box>
                    <StyledSecondaryTitle data-aos="fade-up">
                        Somos una empresa especializada en el <span>diseño y la fabricación de teclados de membrana y
                            caratulas gráficas</span> para todo tipo de máquinas, equipo médico y sistemas electrónicos.
                    </StyledSecondaryTitle>


                </Box>

                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Grid container spacing={5} sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Grid item sm={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', maxWidth: '400px', padding: '12px', alignItems: 'center' }} data-aos="fade-up">
                            <div style={{ maxWidth: '350px', height: 'auto', marginBottom: '16px' }}>
                                <picture>
                                    <source srcSet='./images/materiales.webp' type='image/webp' />
                                    <source srcSet='./images/materiales.png' type='image/png' />
                                    <img src='./images/materiales.webp' alt='ejemplo de teclados de membrana' style={{ width: '100%', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                                </picture>
                            </div>
                            <StyledSubtitle style={{ color: '#FFF', textAlign: 'center', fontSize: '20px', lineHeight: '28px', fontWeight: '700', maxWidth: '312px', marginBottom: '4px' }}>
                                Materiales
                            </StyledSubtitle>
                            <StyledSubtitle style={{ color: '#7D8590', textAlign: 'center', fontSize: '16px', lineHeight: '24px', fontWeight: '500', maxWidth: '312px' }}>
                                Mate y brillante. Embozado.
                            </StyledSubtitle>
                        </Grid>
                        <Grid item sm={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', maxWidth: '400px', padding: '12px', alignItems: 'center' }} data-aos="fade-up">
                            <div style={{ maxWidth: '350px', height: 'auto', marginBottom: '16px' }}>
                                <picture>
                                    <source srcSet='./images/led.webp' type='image/webp' />
                                    <source srcSet='./images/led.png' type='image/png' />
                                    <img src='./images/led.webp' alt='teclado de membrana para equipo medico' style={{ width: '100%', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                                </picture>
                            </div>
                            <StyledSubtitle style={{ color: '#FFF', textAlign: 'center', fontSize: '20px', lineHeight: '28px', fontWeight: '700', maxWidth: '312px', marginBottom: '4px' }}>
                                Circuito Electrónico
                            </StyledSubtitle>
                            <StyledSubtitle style={{ color: '#7D8590', textAlign: 'center', fontSize: '16px', lineHeight: '24px', fontWeight: '500', maxWidth: '312px' }}>
                                Circuito de electrónico. Leds SMD. Conectores rígidos y flexibles.
                            </StyledSubtitle>
                        </Grid>
                        <Grid item sm={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', maxWidth: '400px', padding: '12px', alignItems: 'center' }} data-aos="fade-up">
                            <div style={{ maxWidth: '350px', height: 'auto', marginBottom: '16px' }}>
                                <picture>
                                    <source srcSet='./images/design.webp' type='image/webp' />
                                    <source srcSet='./images/design.png' type='image/png' />
                                    <img src='./images/design.webp' alt='teclado de membrana para equipo medico' style={{ width: '100%', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                                </picture>
                            </div>
                            <StyledSubtitle style={{ color: '#FFF', textAlign: 'center', fontSize: '20px', lineHeight: '28px', fontWeight: '700', maxWidth: '312px', marginBottom: '4px' }}>
                                Ingeniería y Diseño
                            </StyledSubtitle>
                            <StyledSubtitle style={{ color: '#7D8590', textAlign: 'center', fontSize: '16px', lineHeight: '24px', fontWeight: '500', maxWidth: '312px' }}>
                                Diseño completo del producto. Asesoría técnica personalizada. Servicio de entrega.
                            </StyledSubtitle>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <Grid container spacing={5} sx={{ display: 'flex', justifyContent: 'start' }}>
                        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '12px', alignItems: 'center' }} data-aos="fade-up">
                            <div style={{ maxWidth: '300px', height: 'auto' }}>
                                <picture>
                                    <source srcSet='./images/materiales-sqr.webp' type='image/webp' />
                                    <source srcSet='./images/materiales-sqr.png' type='image/png' />
                                    <img src='./images/materiales-sqr.webp' alt='img' style={{ width: '100%', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                                </picture>
                            </div>
                            <div style={{ marginLeft: '12px' }}>
                                <StyledSubtitle style={{ color: '#FFF', textAlign: 'start', fontSize: '20px', lineHeight: '28px', fontWeight: '700', marginBottom: '4px' }}>
                                    Materiales
                                </StyledSubtitle>
                                <StyledSubtitle style={{ color: '#7D8590', textAlign: 'start', fontSize: '16px', lineHeight: '24px', fontWeight: '500' }}>
                                    Mate y brillante. Embozado.
                                </StyledSubtitle>
                            </div>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '12px', alignItems: 'center' }} data-aos="fade-up">
                            <div style={{ maxWidth: '300px', height: 'auto' }}>
                                <picture>
                                    <source srcSet='./images/led-sqr.webp' type='image/webp' />
                                    <source srcSet='./images/led-sqr.png' type='image/png' />
                                    <img src='./images/led-sqr.webp' alt='img' style={{ width: '100%', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                                </picture>
                            </div>
                            <div style={{ marginLeft: '12px' }}>
                                <StyledSubtitle style={{ color: '#FFF', textAlign: 'start', fontSize: '20px', lineHeight: '28px', fontWeight: '700', marginBottom: '4px' }}>
                                    Circuito Electrónico
                                </StyledSubtitle>
                                <StyledSubtitle style={{ color: '#7D8590', textAlign: 'start', fontSize: '16px', lineHeight: '24px', fontWeight: '500' }}>
                                    Circuito de electrónico. Leds SMD. Conectores rígidos y flexibles.
                                </StyledSubtitle>
                            </div>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '12px', alignItems: 'center' }} data-aos="fade-up">
                            <div style={{ maxWidth: '300px', height: 'auto' }}>
                                <picture>
                                    <source srcSet='./images/design-sqr.webp' type='image/webp' />
                                    <source srcSet='./images/design-sqr.png' type='image/png' />
                                    <img src='./images/design-sqr.webp' alt='img' style={{ width: '100%', borderRadius: '10px', boxShadow: '0px 0px 10px #0d1117' }} />
                                </picture>
                            </div>
                            <div style={{ marginLeft: '12px' }}>
                                <StyledSubtitle style={{ color: '#FFF', textAlign: 'start', fontSize: '20px', lineHeight: '28px', fontWeight: '700', marginBottom: '4px' }}>
                                    Ingeniería y Diseño
                                </StyledSubtitle>
                                <StyledSubtitle style={{ color: '#7D8590', textAlign: 'start', fontSize: '16px', lineHeight: '24px', fontWeight: '500' }}>
                                    Diseño completo del producto. Asesoría técnica. Soporte técnico. Servicio de entrega.
                                </StyledSubtitle>
                            </div>
                        </Grid>
                    </Grid>
                </Box>

                <StyledButton style={{ marginBottom: `${showCatalogo ? '0px' : '56px'}`, marginTop: '128px' }} onClick={(e) => handleShowCatalog(e)}>{showCatalogo ? 'Ocultar' : 'Ver Catálogo'}</StyledButton>
                {showCatalogo ? <CatalogoMembranas data-aos="fade-in" /> : null}
                <Box sx={{ marginTop: '86px' }} data-aos='fade-up'>
                    <StyledSubtitle style={{ fontWeight: '600', lineHeight: '42px', fontSize: '36px' }}> Acércate con nosotros, <span>somos el fabricante de membranas líder en México</span>,
                        ofrecemos un servicio de calidad a precios competitivos.</StyledSubtitle>
                    <StyledSubtitle style={{ fontWeight: '600', lineHeight: '42px', fontSize: '36px', marginTop: '12px' }}> ¡Contáctanos ahora!</StyledSubtitle>
                    <Link to='/contacto' style={{ textDecoration: 'none' }}>
                        <StyledButton>Contacto</StyledButton>
                    </Link>
                </Box>

            </Container>
            <Container maxWidth='xxl' style={{ background: '#0d1117', paddingLeft: '0px', paddingRight: '0px' }}>
                <StyledDividerBottom>
                    <img src='./images/section-space-v2.svg' alt='div-img' />
                </StyledDividerBottom>
            </Container>
        </>


    );
};

export default MembranasHome;
